import React from "react"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Layout from "../components/UI/layout"
import TableOfContents from "../components/UI/elements/TableOfContents"
import Sidebar from "../components/UI/elements/Sidebar"

const BlogPost = ({ data }) => {
  if (data === undefined)
    return (
      <Layout>
        <div>
          <p>Build error</p>
        </div>
      </Layout>
    )
  const { frontmatter, body, tableOfContents } = data.mdx

  return (
    <Layout>
      <div className="lg:grid lg:grid-cols-5 lg:gap-x-4 mb-8 mt-4">
        <div className="">
          {tableOfContents?.items && (
            <TableOfContents items={tableOfContents.items} />
          )}
        </div>
        <div className="lg:col-span-3 px-4 lg:pb-96 bg-gray-800 pt-8">
          <h1 className="mb-8 font-bold">{frontmatter.title}</h1>
          <MDXRenderer localImages={frontmatter.embeddedImagesLocal}>
            {body}
          </MDXRenderer>
        </div>
        <div className="mt-16">
          <Sidebar />
        </div>
      </div>
    </Layout>
  )
}

export default BlogPost

export const query = graphql`
  query PostsBySlug($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      body
      frontmatter {
        title
        date(formatString: "YYYY MMMM Do")
        embeddedImagesLocal {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      tableOfContents
    }
  }
`
