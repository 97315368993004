import React, { useEffect, useState } from "react"

function getIds(items) {
  return items.reduce((acc, item) => {
    if (item.url) {
      // url has a # as first character, remove it to get the raw CSS-id
      acc.push(item.url.slice(1))
    }
    if (item.items) {
      acc.push(...getIds(item.items))
    }
    return acc
  }, [])
}

function useActiveId(itemIds) {
  const [activeId, setActiveId] = useState(``)
  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setActiveId(entry.target.id)
          }
        })
      },
      { rootMargin: `0% 0% -80% 0%` }
    )
    itemIds.forEach(id => {
      observer.observe(document.getElementById(id))
    })
    return () => {
      itemIds.forEach(id => {
        observer.unobserve(document.getElementById(id))
      })
    }
  }, [itemIds])
  return activeId
}

function renderItems(items, activeId) {
  return (
    <ol key={activeId} className="list-outside pl-4">
      {items.map(item => {
        return (
          <li key={item.title} className="list-none ml-0">
            <a
              href={item.url}
              className={`${
                activeId === item.url.slice(1)
                  ? "underline text-cyan-300 font-normal"
                  : "text-gray-300 font-extralight"
              } hover:underline hover:text-cyan-300 hover:font-normal underline-offset-2`}
            >
              {item.title}
            </a>
            {item.items && renderItems(item.items, activeId)}
          </li>
        )
      })}
    </ol>
  )
}

const TableOfContents = ({ items }) => {
  const idList = getIds(items)
  const activeId = useActiveId(idList)
  return (
    <div className="hidden lg:block -ml-4 mt-24 sticky top-8">
      <h2 className="ml-4">In this article</h2>
      {renderItems(items, activeId)}
    </div>
  )
}

export default TableOfContents
