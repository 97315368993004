import React from "react"
import { RadioGroup } from "@headlessui/react"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

const Assetlist = ({ assets, asset, setAsset }) => {
  return (
    <div>
      <div className="flex items-center justify-between">
        <h2 className="ml-1">Assets mentioned</h2>
      </div>

      <RadioGroup value={asset} onChange={setAsset} className="mt-2 -mx-1 mb-4">
        <RadioGroup.Label className="sr-only">Choose an asset</RadioGroup.Label>
        <div className="flex flex-wrap">
          {assets.map(option => (
            <RadioGroup.Option
              key={option.name}
              value={option}
              className={({ active, checked }) =>
                classNames(
                  active ? "bg-gray-600" : "",
                  checked ? "bg-gray-700" : "bg-gray-800 hover:bg-gray-700",
                  "m-1 py-1 px-2 rounded-xl flex items-center justify-center text-sm"
                )
              }
            >
              <RadioGroup.Label as="p" className="mb-0">
                {option.name}
              </RadioGroup.Label>
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
    </div>
  )
}

export default Assetlist
