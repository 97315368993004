import React from "react"
import { usePortfolio } from "../../../context/PortfolioContext"

const Assetdetail = ({ symbol }) => {
  const { holdings, mv } = usePortfolio()
  const holding = holdings.find(h => h.asset.symbol === symbol)
  const rowClasses = "w-24 ml-1 font-normal text-cyan-300"
  if (!holding) {
    return <p>No holding information on {symbol}</p>
  }
  return (
    <table className="table-fixed text-white font-extralight w-full">
      <tbody>
        <tr>
          <td className={rowClasses}>Name</td>
          <td className="text-right">{holding.asset.name}</td>
        </tr>
        <tr>
          <td className={rowClasses}>Category</td>
          <td className="text-right">{holding.asset.categoryName}</td>
        </tr>
        <tr>
          <td className={rowClasses}>Ecosystem</td>
          <td className="text-right">{holding.asset.ecosystemName}</td>
        </tr>
        <tr>
          <td className={rowClasses}>Allocation</td>
          <td className="text-right">{`${(
            ((holding.price.price * holding.units) / mv) *
            100
          ).toFixed(2)}%`}</td>
        </tr>
        <tr>
          <td className={rowClasses}>Price</td>
          <td className="text-right">{`$${
            holding.price.price < 0.1
              ? holding.price.price.toFixed(4)
              : holding.price.price.toFixed(2)
          }`}</td>
        </tr>
        <tr>
          <td className={rowClasses}>YTD perf.</td>
          <td className="text-right">{`${(
            holding.performance.eoly * 100
          ).toFixed(2)}%`}</td>
        </tr>
        <tr>
          <td className={rowClasses}>MTD perf.</td>
          <td className="text-right">{`${(
            holding.performance.eolm * 100
          ).toFixed(2)}%`}</td>
        </tr>
      </tbody>
    </table>
  )
}

export default Assetdetail
