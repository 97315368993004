import React, { useState } from "react"
import Assetlist from "./Assetlist"
import Assetdetail from "./Assetdetail"

const assets = [
  { name: "BTC" },
  { name: "SOL" },
  { name: "ETH" },
  { name: "BNB" },
  { name: "TRX" },
  { name: "VTH" },
]

const Sidebar = () => {
  const [asset, setAsset] = useState(assets[0])

  return (
    <div className="max-w-xs lg:sticky lg:top-8 lg:max-w-lg pt-8">
      <Assetlist assets={assets} asset={asset} setAsset={setAsset} />
      <Assetdetail symbol={asset.name} />
    </div>
  )
}

export default Sidebar
